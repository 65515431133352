<script setup>

</script>
<template>
    <!-- Begin page -->
    <div id="layout-wrapper">

        <Component
            v-bind="$page.props"
            v-if="$root.modalComponent"
            :is="$root.modalComponent"
        />


        <header_top/>

        <!-- Start Page Content -->
        <transition name="op" mode="in-out">
            <sidebar_menu/>
        </transition>

        <div class="main-content">

            <div class="page-content">

                <div class="container-fluid">

                    <div class="row">
                        <div class="col-12">
                            <div class="page-title-box d-flex align-items-center justify-content-between">
                                <h4 class="mb-0 font-size-18"></h4>
                                <div class="page-title-right">
<!--                                    <breadcrumb></breadcrumb>-->
                                </div>
                            </div>
                        </div>
                    </div>


                    <slot/>

                </div>
            </div>

            <footer_page/>

        </div>
        <!-- End Page Content -->
    </div>
    <!-- END layout-wrapper -->

    <!-- Right Sidebar -->

    <!-- Right bar overlay-->
    <div class="rightbar-overlay"></div>
</template>
