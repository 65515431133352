<script setup>

</script>
<template>
    <header id="page-topbar">
        <div class="navbar-header">
            <div class="d-flex">
                <!-- App Logo-->
                <app_logo/>
                <!-- Button Bars-->
                <button_bars/>
                <!-- App Search-->
<!--                <search/>-->
            </div>
            <div class="d-flex">
                <!-- App Mobile Serch-->
<!--                <mobile_search/>-->
                <!--App Button FullScreen-->
                <button_full_screen/>
                <!--App Button RigthBar-->
<!--
                <AppButtonRigthBar/>
-->
                <!--App Notifications-->
<!--
                <AppNotifications/>
-->
                <!--App Menu Profile-->
                <menu_profile/>
            </div>
        </div>
    </header>
</template>
<style scoped>

</style>
